export default {
    home: 'الصفحة الرئيسية',
    'scientific-office': 'المكتب العلمي',
    visits: 'الزيارات',
    'med-rep-plan': 'خطة مندوب',
    'day-off-requests': 'طلبات الإجازة',
    'day-off-request': 'طلب إجازة',
    reports: 'تقارير',
    'med-rep-mark': 'تقرير تواتر الزيارات للمندوبين',
    exams: 'امتحانات',
    salaries: 'الرواتب',
    storehouse: 'المستودع',
    'delivery-materials': 'حركة مواد',
    'items-journal': 'يومية المستودع',
    statement: 'جرد',
    management: 'إدارة',
    staff: 'طاقم العمل',
    customers: 'العملاء',
    'managing-roles': 'إدارة الأدوار',
    'external-entities': 'الجهات الخارجية',
    products: 'المنتجات',
    'regions-management': 'إدارة المناطق',
    'my-profile': 'الملف الشخصي',
    'my-visits': 'زياراتي',
    'my-report': 'تقريري',
    'my-plan': 'خطتي',
    companies: 'الشركات',
    logout: 'تسجيل الخروج',
    materials: 'المواد المخطط لأخذها',
    'loaned-materials': 'المواد المعارة',

    'add-day-off-request': 'إضافة طلب إجازة',
    'products-statement': 'جرد المواد',
    'minimal-interface': 'مواد على وشك النفاذ',
    'warehouses-statistics': 'إحصائيات المستودع',
    'supervisor-remaining-samples': 'المواد المتبقية'
}