import * as types from '../mutation-types';
import { permissions, roleName } from '@/helpers/enums'; 

export default {
	namespaced: true,
	state: {
		authenticated: false,
		isSuperAdmin: false,
		userData: {
			name: null,
			employeeType: null,
			employeeId: null,
			permissions: [],
			id: null
		},
		self: {
			username: null,
		},
		youPermissions: []
	},

	actions: {
		login({ commit, dispatch }, { username, password }) {
			return window.axios.post('/users/authenticate', { username, password })
			.then((response) => {
				// console.log(arr)
				/* 
				the following (if statement) is to check if how is logging in is medical rep
				and if medical rep log in will throw exciption with message and (name=42)
				*/
				// const arr = response.data.role.permissions
				if (response.data.userType === 1){
					var e = new Error('It is NOT ALLOWED to medical reps to enter the system');
					e.name = 42;
					throw e;
				}
				
				const auth = {
					username, 
					password, 
					employeeId: response.data.employeeId, 
					id: response.data.id,
					token:response.data.token, 
					authenticated: true,
					isSuperAdmin: response.data.isSuperAdmin,
					permissions:  response.data.role !== null ? response.data.role.permissions : []
				};

				// if (response.data.isSuperAdmin === false) {
				// 	const youPermissions = {
				// 		leave: response.data.role ? response?.data.role.permissions.find(c => c.permissionsNames === permissions.Leaves)?.rolePermissions : null,

				// 		AddLeavesForStaff: response.data.role ? response?.data.role.permissions.find(c => c.permissionsNames === permissions.AddLeavesForStaff)?.rolePermissions : null,

				// 		ManageLeaveBalances: response?.data.role.permissions.find(c => c.permissionsNames === permissions.ManageLeaveBalances)?.rolePermissions,

				// 		GenerateMaterials: response?.data.role.permissions.find(c => c.permissionsNames === permissions.GenerateMaterials)?.rolePermissions,

				// 		MaterialTransactions: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MaterialTransactions)?.rolePermissions,

				// 		MedicalPlans: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MedicalPlans)?.rolePermissions,

				// 		MedicalVisits: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MedicalVisits)?.rolePermissions,
						
				// 		WeeklyReports: response?.data.role.permissions.find(c => c.permissionsNames === permissions.WeeklyReports)?.rolePermissions,

				// 		MonthlyPlanReports: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MonthlyPlanReports)?.rolePermissions,

				// 		Reviews: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Reviews)?.rolePermissions,

				// 		Exams: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Exams)?.rolePermissions,

				// 		Salaries: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Salaries)?.rolePermissions,
						
				// 		MedicalReps: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MedicalReps)?.rolePermissions,

				// 		Entities: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Entities)?.rolePermissions,

				// 		EntityModificationRequests: response?.data.role.permissions.find(c => c.permissionsNames === permissions.EntityModificationRequests)?.rolePermissions,

				// 		Charts: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Charts)?.rolePermissions,

				// 		Regions: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Regions)?.rolePermissions,

				// 		Users: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Users)?.rolePermissions,
						
				// 		Roles: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Roles)?.rolePermissions,
				// 		GeneralFilter: response?.data.role.permissions.find(c => c.permissionsNames === permissions.GeneralFilter)?.rolePermissions 
				// 	}
				// 	commit(types.PERMISSIONS, youPermissions)
				// } else {
				// 	const youPermissions = {}
				// 	commit(types.PERMISSIONS, youPermissions)
				// }
				localStorage.setItem('auth', JSON.stringify(auth));
				window.axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.token;
				return dispatch('getSelf').then((response) => {
					commit(types.LOGIN, auth);
					return response.data;
				})
				// dispatch('users/getself', null, { root: true }).then(() => {
				// 	commit(types.LOGIN, auth);
				// });
			})
		},
	
		logout({ commit }) {
			localStorage.removeItem('auth');
			delete window.axios.defaults.headers.common.Authorization;
			commit(types.LOGOUT);
		},

		getSelf({ commit }){
			return	window.axios.get('/users/getself').then((response) => {
				if (response.data.role !== null) {
					const youPermissions = {
						Leaves: response.data.role ? response?.data.role.permissions.find(c => c.permissionsNames === permissions.Leaves)?.rolePermissions : null,

						AddLeavesForStaff: response.data.role ? response?.data.role.permissions.find(c => c.permissionsNames === permissions.AddLeavesForStaff)?.rolePermissions : null,

						ManageLeaveBalances: response?.data.role.permissions.find(c => c.permissionsNames === permissions.ManageLeaveBalances)?.rolePermissions,

						GenerateMaterials: response?.data.role.permissions.find(c => c.permissionsNames === permissions.GenerateMaterials)?.rolePermissions,

						MaterialTransactions: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MaterialTransactions)?.rolePermissions,

						MedicalPlans: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MedicalPlans)?.rolePermissions,

						MedicalVisits: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MedicalVisits)?.rolePermissions,
						
						WeeklyReports: response?.data.role.permissions.find(c => c.permissionsNames === permissions.WeeklyReports)?.rolePermissions,

						MonthlyPlanReports: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MonthlyPlanReports)?.rolePermissions,

						Reviews: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Reviews)?.rolePermissions,

						Exams: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Exams)?.rolePermissions,

						Salaries: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Salaries)?.rolePermissions,
						
						MedicalReps: response?.data.role.permissions.find(c => c.permissionsNames === permissions.MedicalReps)?.rolePermissions,

						Entities: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Entities)?.rolePermissions,

						EntityModificationRequests: response?.data.role.permissions.find(c => c.permissionsNames === permissions.EntityModificationRequests)?.rolePermissions,

						Charts: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Charts)?.rolePermissions,

						Regions: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Regions)?.rolePermissions,

						Users: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Users)?.rolePermissions,
						
						Roles: response?.data.role.permissions.find(c => c.permissionsNames === permissions.Roles)?.rolePermissions,
						GeneralFilter: response?.data.role.permissions.find(c => c.permissionsNames === permissions.GeneralFilter)?.rolePermissions,

						ManageTeam:  response?.data.role.permissions.find(c => c.permissionsNames === permissions.ManageTeam)?.rolePermissions
					}
					commit(types.PERMISSIONS, youPermissions)
				} else {
					const youPermissions = {}
					commit(types.PERMISSIONS, youPermissions)
				}
				commit(types.GET_USER_DATA, response.data)
				return response.data;
			})
		},
	},

	mutations: {
		[types.GET_USER_DATA](state, userData, permissions){
			state.userData = userData;
			// state.userData.permissions = userData.role.permissions
		},

		[types.LOGIN](state, payload) {
			state.authenticated = true;
			state.self.username = payload.username;
			state.userData.name = payload.name
			state.userData.employeeId = payload.employeeId;
			state.userData.id = payload.id;
			state.isSuperAdmin = payload.isSuperAdmin
		},
	
		[types.LOGOUT](state) {
			state.authenticated = false;
			state.self.username = null;
		},

		[types.PERMISSIONS] (state, youPermissions) {
			state.youPermissions = youPermissions
		}
	},

	getters:{
		isAdmin: state => Boolean(state.userData.employeeType === 2),
		getUserType: state => {
			// if (state.userData.employeeType === 0) return 'supervisor'
			// else if (state.userData.employeeType === 1) return 'medical rep'
			// else if (state.userData.employeeType === 2) return 'admin'
		}
	},

	modules: {
	},
}