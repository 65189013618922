import Home from '../pages/Home';
import Login from '../pages/signs/Login';
import StatisticsChart from '../pages/StatisticsChart'

// marketing
import Visits from '../pages/marketing/Visits';
import DayOffRequests from '../pages/marketing/DayOffRequests';
import Staff from '../pages/marketing/Staff';
import Supervisor from '../pages/marketing/Supervisors';
import SupervisorsReports from '../pages/marketing/SupervisorsReports';
import Samples from '../pages/marketing/Samples';
import Customers from '../pages/marketing/Customers';
import Exams from '../pages/marketing/Exams';
import Salaries from '../pages/marketing/Salaries';
import EmployeeData from '../pages/marketing/views/EmployeeData'
// pages in marketing
import VisitedDoctors from '../pages/marketing/views/VisitedDoctors'
import medicalRepSamples from '../pages/marketing/views/medicalRepSamples'
import SupervisorRemainingSamples from '../pages/marketing/views/SupervisorRemainingSamples'
import ModificationRequests from '../pages/marketing/views/ModificationRequests'
import SpecializationsManagement from '../pages/marketing/views/SpecializationsManagement'
import SupervisorsReport from '../pages/marketing/views/SupervisorsReport'
import WeeklyReport from '../pages/marketing/views/WeeklyReport'
import Regions from '../pages/marketing/Regions';
import MedicalRepRates from '../pages/marketing/views/MedicalRepRates';
import ViewExamResults from '../pages/marketing/views/ViewExamResults';
import Companies from '../pages/marketing/Companies';

// sales
import Products from '../pages/sales/Products';
import Sales from '../pages/sales/Sales';
import Statistics from '../pages/sales/Statistics';
// pages in sales
import PharmaceuticalForms from '../pages/sales/views/PharmaceuticalForms'
import Warehouses from '../pages/sales/views/Warehouses';

// settings
import ManagingRolesAndPermissions from '../pages/settings/ManagingRolesAndPermissions';
import Users from '../pages/settings/Users';

// staff
import MedicalRepInfo from '../pages/marketing/MedicalRepInfo'
import SupervisorsInfo from '../pages/marketing/SupervisorsInfo'

import SamplesTabs from '../pages/marketing/SamplesTabs'
import SamplesStatement from '../pages/marketing/SamplesStatement'
// import NewReport from '../pages/marketing/NewReport'

import ClassificationManagement from '../pages/marketing/views/ClassificationManagement'
import JournalDeliveryOfSamples from '../pages/marketing/JournalDeliveryOfSamples'

import VisitFrequencyReport from '../pages/marketing/VisitFrequencyReport'
import MatchingRepPlan from '../pages/marketing/MatchingRepPlan'
import MedicalRepPlan from '../pages/marketing/MedicalRepPlan'

import Market from '../pages/marketing/Market'
import DoubleVisitReport from '../pages/reports/DoubleVisitReport'
import DoubleVisitsReports from '../pages/reports/DoubleVisitsReports'
import Reports from '../pages/reports/Reports'

import UsersTree from '../pages/settings/UsersTree'
import AdministrativeVisit from '../pages/marketing/AdministrativeVisit.vue' 

import AdministrativePlan from '../pages/marketing/AdministrativePlan.vue'
import JournalItems from '../pages/marketing/JournalItems'
import AccountsTree from '../pages/marketing/AccountsTree'
import Teams from '../pages/settings/Teams'

import Materials from '../pages/marketing/Materials'
import LoanedItems from '../pages/marketing/LoanedItems'

import EvaluationReport from '../pages/reports/EvaluationReport'
import MinimalInterface from '../pages/marketing/views/MinimalInterface'

import DoctorAndMaterials from '../pages/marketing/DoctorAndMaterials'
import ProductsStatement from '../pages/marketing/ProductsStatement'

import WarehousesStatistics from '../pages/WarehousesStatistics'
import VisitsStat from '../pages/marketing/views/VisitsStat';
export default [
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            i18nKey: 'home'
        }
    },
    {
        name: 'login',
        path: '/login',
        component: Login,
    },
    {
        name: 'statistics-chart',
        path: '/statistics-chart/:type',
        component: StatisticsChart,
        meta: {
            i18nKey: 'statistics-chart'
        }
    },

    // marketing
    {
        name: 'visits',
        path: '/visits',
        component: Visits,
        meta: {
            i18nKey: 'visits'
        }
    },
    {
        name: 'day-off-requests',
        path: '/day-off-requests',
        component: DayOffRequests,
        meta: {
            i18nKey: 'day-off-requests'
        }
    },
    {
        name: 'staff',
        path: '/staff',
        component: Staff,
        meta: {
            i18nKey: 'staff'
        }
    },
    {
        name: 'supervisors-reports',
        path: '/weekly-reports',
        component: SupervisorsReports,
        meta: {
            i18nKey: 'supervisors-reports'
        },
        permissionKey: 'WeeklyReports'
    },
    {
        name: 'reports',
        path: '/reports',
        component: Reports,
        meta: {
            i18nKey: 'reports'
        },
        children: [
            {
                name: 'administrative-reports',
                path: '/reports/supervisors-reports',
                component: SupervisorsReports,
                meta: {
                    i18nKey: 'administrative-reports'
                },
                permissionKey: 'WeeklyReports'
            },
            {
                name: 'double-visits',
                path: '/reports/double-visits-reports',
                component: DoubleVisitsReports,
                meta: {
                    i18nKey: 'double-visits'
                }
            }
        ]
    },
    {
        name: 'supervisors',
        path: '/supervisors',
        component: Supervisor,
        meta: {
            i18nKey: 'supervisors'
        }
    },
    {
        name: 'samples',
        path: '/samples',
        component: Samples,
    },
    {
        name: 'customers',
        path: '/customers',
        component: Customers,
        meta: {
            i18nKey: 'customers'
        }
    },
    {
        name: 'Exams',
        path: '/exams',
        component: Exams,
        meta: {
            i18nKey: 'exams'
        },
        permissionKey: 'Exams'
    },
    {
        name: 'salaries',
        path: '/salaries',
        component: Salaries,
        meta: {
            i18nKey: 'salaries'
        },
        permissionKey: 'Salaries'
    },
    {
        name: 'regions-management',
        path: '/regions-management',
        component: Regions,
        meta: {
            i18nKey: 'regions-management'
        }
    },

    // pages in marketing
    {
        name: 'visited-doctors',
        path: '/visited-doctors',
        component: VisitedDoctors,
        meta: {
            i18nKey: 'visited-doctors'
        }
    },
    {
        name: 'medical-rep-samples',
        path: '/medical-rep-samples/:id',
        component: medicalRepSamples,
        meta: {
            i18nKey: 'medical-rep-samples'
        }
    },
    {
        name: 'supervisor-remaining-samples',
        path: '/supervisor-remaining-samples',
        component: SupervisorRemainingSamples,
        meta: {
            i18nKey: 'supervisor-remaining-samples'
        }
    },
    {
        name: 'modification-requests',
        path: '/modification-requests',
        component: ModificationRequests,
        meta: {
            i18nKey: 'modification-requests'
        },
        permissionKey: 'EntityModificationRequests'
    },
    {
        name: 'specializations-management',
        path: '/specializations-management',
        component: SpecializationsManagement,
        meta: {
            i18nKey: 'specializations-management'
        }
    },
    {
        name: 'medical-rep-rates',
        path: '/medical-rep-rates/:medicalRepName',
        component: MedicalRepRates,
        meta: {
            i18nKey: 'medical-rep-rates'
        }
    },
    {
        name: 'exam-results',
        path: '/exam-results/:id',
        component: ViewExamResults,
        meta: {
            i18nKey: 'exam-results'
        },
        permissionKey: 'exams'
    },
    {
        name: 'medical-rep-info',
        path: '/medical-rep-info',
        component: MedicalRepInfo,
        children: [
            {
                name: 'medical-rep-info-day-requests',
                path: '/day-requests/:id',
                component: DayOffRequests,
                meta: {
                    i18nKey: 'medical-rep-info-day-requests'
                },
                permissionKey: 'Leaves'
            },
            {
                name: 'medical-samples',
                path: '/medical-samples/:id',
                component: medicalRepSamples,
                meta: {
                    i18nKey: 'medical-samples'
                }
            },
            {
                name: 'medical-rates',
                path: '/medical-rates/:id',
                component: MedicalRepRates,
                meta: {
                    i18nKey: 'medical-rates'
                }
            },
            {
                name: 'medical-rep-personal-data',
                path: '/medical-rep-personal-data/:id?',
                component: EmployeeData,
                meta: {
                    i18nKey: 'medical-rep-personal-data'
                }
            }
        ]
    },

    {
        name: 'supervisors-info',
        path: '/supervisors-info',
        component: SupervisorsInfo,
        children: [
            {
                name: 'supervisors-samples',
                path: '/supervisors-samples/:id',
                component: Samples,
                meta: {
                    i18nKey: 'supervisors-samples'
                }
            },
            {
                name: 'supervisors-personal-data',
                path: '/supervisors-personal-data/:id',
                component: EmployeeData,
                meta: {
                    i18nKey: 'supervisors-personal-data'
                }
            }
        ]
    },
    // {
    //     name: 'samples-tabs',
    //     path: '/samples-tabs',
    //     component: SamplesTabs,
    //     children: [
    //         {
    //             name: 'samples',
    //             path: '/samples-tabs/samples',
    //             component: Samples,
    //             meta: {
    //                 title: 'Samples'
    //             }
    //         },
    //         {
    //             name: 'samples-statement',
    //             path: '/samples-tabs/samples-statement',
    //             component: SamplesStatement,
    //             meta: {
    //                 title: 'Samples'
    //             }
    //         },
    //         {
    //             name: 'journal-items',
    //             path: '/journal-items',
    //             component: JournalItems,
    //             meta: {
    //                 title: 'Journal items'
    //             }
    //         },
    //     ]
    // },
    {
        name: 'journal-items',
        path: '/journal-items',
        component: JournalItems,
        meta: {
            i18nKey: 'journal-items'
        }
    },
    {
        name: 'samples-statement',
        path: '/samples-tabs/samples-statement',
        component: SamplesStatement,
        meta: {
            i18nKey: 'materials-statement'
        }
    },
    
    {
        name: 'journal-delivery-of-samples',
        path: '/journal-delivery-of-samples',
        component: JournalDeliveryOfSamples,
        meta: {
            i18nKey: 'journal-delivery-of-samples'
        }
    },
    {
        name: 'supervisors-report',
        path: '/supervisors-report/:id?',
        component: SupervisorsReport,
        meta: {
            i18nKey:'supervisors-report'
        }
    },
    {
        name: 'weekly-report',
        path: '/weekly-report/:id?',
        component: WeeklyReport,
        meta: {
            i18nKey:'weekly-report'
        }
    },
    // sales
    {
        name: 'products',
        path: '/products',
        component: Products,
        meta: {
            i18nKey: 'products'
        }
    },
    {
        name: 'sales',
        path: '/sales',
        component: Sales,
    },
    {
        name: 'statistics',
        path: '/statistics',
        component: Statistics,
    },
    // pages in sales
    {
        name: 'pharmaceutical-forms',
        path: '/pharmaceutical-forms',
        component: PharmaceuticalForms,
        meta: {
            i18nKey: 'categories'
        }
    },
    {
        name: 'warehouses',
        path: '/warehouses',
        component: Warehouses,
    },

    // sitting
    {
        name: 'Roles',
        path: '/Roles/:id?',
        component: ManagingRolesAndPermissions,
        meta: {
            i18nKey: 'roles'
        },
        permissionKey: 'Roles'
    },
    {
        name: 'users',
        path: '/users',
        component: Users,
        meta: {
            i18nKey: 'users'
        }
    },

    { 
        name: 'classification-management',
        path: '/classification-management',
        component: ClassificationManagement,
        meta: {
            i18nKey: 'classification-management'
        }
    },

    {
        name: 'visit-frequency-report',
        path: '/visit-frequency-report',

        component: VisitFrequencyReport,
        meta: {
            i18nKey: 'visit-frequency-report'
        }
    },
    {
        name: 'medical-rep-plan',
        path: '/medical-rep-plan/:id?',
        component: MedicalRepPlan,
        meta: {
            i18nKey: 'medical-rep-plan'
        },
        permissionKey: 'MedicalPlans'
    },
    {
        name: 'matching-rep-plan',
        path: '/matching-rep-plan',
        component: MatchingRepPlan
    },
    {
        name: 'market',
        path: '/medical-rep-visit',
        component: Market
    },
    {
        name: 'double-visit-report',
        path: '/double-visit-report/:id?',
        component: DoubleVisitReport,
        meta: {
            i18nKey: 'double-visit-report'
        }
    },
    {
        name: 'double-visits-reports',
        path: '/double-visits-reports',
        component: DoubleVisitsReports,
        meta: {
            i18nKey: 'double-visits-reports'
        }
    },
    {
        name: 'users-tree',
        path: '/users-tree/:id?',
        component: UsersTree,
        meta: {
            i18nKey: 'users-tree'
        }
    },
    {
        name: 'administrative-visit',
        path: '/administrative-visit',
        component: AdministrativeVisit,
        meta: {
            i18nKey: 'administrative-visit'
        }
    },
    {
        name: 'administrative-plan',
        path: '/administrative-plan/:id?',
        component: AdministrativePlan,
        meta: {
            i18nKey: 'administrative-plan'
        }
    },
    {
        name: 'accounts-tree',
        path: '/accounts-tree',
        component: AccountsTree,
        meta: {
            i18nKey: 'accounts-tree'
        }
    },
    {
        name: 'teams',
        path: '/teams',
        component: Teams,
        meta: {
            i18nKey: 'teams'
        },
        permissionKey: 'Roles'
    },
    {
        name: 'companies',
        path: '/companies',
        component: Companies,
        meta: {
            i18nKey: 'companies'
        }
    },
    {
        name: 'materials',
        path: '/materials',
        component: Materials,
        meta: {
            i18nKey: 'materials'
        }
    },
    {
        name: 'loaned-items',
        path: '/loaned-items',
        component: LoanedItems,
        meta: {
            i18nKey: 'loaned-items'
        }
    },
    {
        name: 'evaluation-report',
        path: '/evaluation-report',
        component: EvaluationReport,
        meta: {
            i18nKey: 'evaluation-report'
        }
    },
    {
        name: 'minimal-interface',
        path: '/minimal-interface',
        component: MinimalInterface,
        meta: {
            i18nKey: 'minimal-interface'
        }
    },
    {
        name: 'doctor-and-materials',
        path: '/doctor-and-materials',
        component: DoctorAndMaterials,
        meta: {
            i18nKey: 'doctor-and-materials'
        }
    },
    {
        name: 'products-statement',
        path: '/products-statement',
        component: ProductsStatement,
        meta: {
            i18nKey: 'products-statement'
        }
    },
    {
        name: 'warehouses-statistics',
        path: '/warehouses-statistics',
        component: WarehousesStatistics,
        meta: {
            i18nKey: 'warehouses-statistics'
        }
    },
    {
        path: '*',
        redirect: '/'
    },
    {
        name: 'visits-stat',
        path: '/visits-stat',
        component: VisitsStat,
        meta: {
            i18nKey: 'visits-stat'
        }
    }
]