<template>
    <div>
        <v-row class="mt-3">
            <!-- filter -->
            <!-- <v-col cols="2">
                <bee-date-input
					v-model="fromDate"
					:label="$t('inputs.from-date')"
					input-format-order="DD-MM-YYYY"
					dense
					:loading="loading"
					hide-details
				></bee-date-input>
            </v-col>

            <v-col cols="2">
                <bee-date-input
					v-model="toDate"
					:label="$t('inputs.to-date')"
					input-format-order="DD-MM-YYYY"
					dense
					:loading="loading"
					hide-details
				></bee-date-input>
            </v-col> -->

            <v-col cols="auto" class="pa-0">
                <v-radio-group
                    v-model="dateType"
                    row
                    class="mt-3"
                    hide-details
                >
                    <v-radio
                        :label="$t('daily-date')"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        :label="$t('from-date-to-date')"
                        :value="2"
                    ></v-radio>
                </v-radio-group>
            </v-col>

            <!-- start date -->
            <v-col v-if="dateType === 2" md="2" sm="4" cols="6">
                <bee-date-picker
                    v-model="fromDate"
                    :input-label="$t('inputs.start-date')"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true,
                        clearable: true,
                    }"
                ></bee-date-picker>
            </v-col>
            
            <!-- end date -->
            <v-col v-if="dateType === 2" cols="6" sm="3" md="2">
                <bee-date-picker
                    v-model="toDate"
                    :input-label="$t('inputs.end-date')"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true,
                        clearable: true,
                    }"
                ></bee-date-picker>
            </v-col>

            <v-col v-if="dateType === 1" cols="12" sm="6" md="3" >
                <bee-date-input
                    v-model="date"
                    :append-icon="$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left'"
                    :prepend-icon="$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right'"
                    :label="$t('inputs.date')"
                    input-format-order="DD-MM-YYYY"
                    dense
                    :loading="loading"
                    hide-details
                    @click:append="
                        needToRestShownItems = true;
                        addDate();
                    "
                    @click:prepend="
                        needToRestShownItems = true;
                        subDate();
                    "
                ></bee-date-input>
            </v-col>
            
            <v-col cols="auto">
                <v-autocomplete 
                    v-model="employeeId"
                    :items="medicalReps"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    :disabled="loading"
                    :label="$t('inputs.account')"
                />
            </v-col>

            <v-col cols="auto">
                <v-autocomplete 
                    v-model="entityType"
                    :items="entityTypeArray"
                    item-value="id"
                    item-text="name"
                    :label="$t('inputs.type')"
                    hide-details
                    outlined
                    dense
                />
            </v-col>

            <!-- <v-spacer /> -->

            <v-col cols="auto">
                <v-btn
                    color="success"
                    class="px-sm-4 me-2"
                    :loading="loading"
                    :disabled="loading"
                    small
                    elevation="0"
                    @click="getStat()"
                >
                    <v-icon> mdi-magnify </v-icon>
                    {{ $t('search') }}
                </v-btn>
                <v-btn
                    color="alert-color white--text"
                    class="px-sm-8"
                    :disabled="loading"
                    small
                    elevation="0"
                >
                    {{ $t('reset') }}
                </v-btn>
            </v-col>
        </v-row>
        <!-- table -->
        <bee-handy-table
            :items="visitCount"
            :headers="headers"
            dense
            zebra
            :loading="loading"
            :items-per-page="-1"
            hide-default-footer
            pagination-on-scroll
            fixed-header
            :height="$vuetify.breakpoint.height - 150"
        ></bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment'
import { entityTypeArray } from '@/helpers/enums'
export default {
    data() {
        return {
            loading: false,
            employeeId: null,
            fromDate: moment(moment().startOf('month')).format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            visits: [],
            entityTypeArray,
            visitCount: [],
            entityType: null,
            dateType: 1,
            date: moment().format('YYYY-MM-DD')
        }
    },

    watch: {
        employeeId(val) {
            if (val === null) {
                this.employeeId = this.medicalReps.find(c => c.id === this.userIsLogin)?.id
            }
        }
    },

    computed: {
        ...mapState({
            userIsLogin: state => state.auth.userData.employeeId,
            medicalReps: state => state.medicalreps.medicalreps
        }),

        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById'
        }),

        headers() {
            return [
                {
                    text: this.$t('headers.name'),
                    name: 'employeeId',
                    value: 'employeeId',
                    setValueExpr: val => this.getMedicalRepById(val)?.name
                },
                {
                    text: this.$t('headers.visit-count'),
                    name: 'fromToDateVisitCount',
                    value: 'fromToDateVisitCount'
                },
                {
                    text: this.$t('headers.total-visit-count'),
                    name: 'totalVisitCount',
                    value: 'totalVisitCount'
                }
            ]
        }
    },

    methods: {
        moment,

        getStat() {
            this.loading = true
            this.$store.dispatch('visits/fetchStatEmployee', { 
                employeeId: this.employeeId,
                fromDate: this.dateType === 2 ? this.fromDate : this.moment().format('YYYY-MM-DD'),
                toDate: this.toDate,
                entityType: this.entityType,
                // regionIds,
                //  employeeId, 
                //  employeeIds,
                //   entityName, 
                //   entityId, specialtyId, workload, orderByWorkLoad, orderByName 
            }).then((data) => {
                this.visitCount = data
            }).finally(() => {
                this.loading = false
            })
        },

        addDate () {
            this.date = moment(this.date).add(1, 'day').format('YYYY-MM-DD');
            return this.date
        },

        subDate() {
            this.date = moment(this.date).subtract(1, 'day').format('YYYY-MM-DD')
            return this.date
        },
    },

    created() {
        this.loading = true
        Promise.all([
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            this.getStat()
        ]).finally(() => {
            this.loading = false
        })
    }
}
</script>
